@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  /* --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%); */
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --gradient-text: linear-gradient(89.97deg, firebrick 1.84%, tomato 102.67%);
  --gradient-bar: linear-gradient(
    103.22deg,
    firebrick -13.86%,
    tomato 99.55%
  );

  --bg-color-main: #040c18;
  --bg-color-article: #040c18;
  /* --bg-color-button: firebrick; */
  --bg-color-button: tomato;

  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
}
